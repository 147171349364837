<template>
    <section class="hero rates-hero is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-6">
                            <div class="hero-box-left">
                                <p class="title hero-title">
                                    Rates and Fees
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </section>

    <div class="blue-bg is-hidden-widescreen">
        <section class="rates-hero-mobile">
        </section>

        <section class="section">
            <div class="container">
                <p class="title hero-title-mobile">
                    Rates and Fees
                </p>
            </div>
        </section>
    </div>

    <section class="section">
        <div class="container has-text-centered">
            <h2 class="is-size-3">Our Current Rates</h2>

            <div class="columns">
                <div class="column is-6 is-offset-3">

            <div class="table-container">
                <table class="table is-striped is-fullwidth is-hoverable">
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th class="has-text-right">Annual Percentage Yield</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Savings<sup>*</sup></th>
                            <td class="has-text-right">0.25%</td>
                        </tr>
                        <tr>
                            <th>3 Month CD<sup>**</sup></th>
                            <td class="has-text-right">3.50%</td>
                        </tr>
                        <tr>
                            <th>6 Month CD<sup>**</sup></th>
                            <td class="has-text-right">3.50%</td>
                        </tr>
                        <tr>
                            <th>1 Year CD<sup>**</sup></th>
                            <td class="has-text-right">3.50%</td>
                        </tr>
                        <tr>
                            <th>2 Year CD<sup>**</sup></th>
                            <td class="has-text-right">0.25%</td>
                        </tr>
                        <tr>
                            <th>3 Year CD<sup>**</sup></th>
                            <td class="has-text-right">0.25%</td>
                        </tr>
                        <tr>
                            <th>5 Year CD<sup>**</sup></th>
                            <td class="has-text-right">0.25%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="has-text-left">
            <p><small><em><sup>*</sup>Minimum account balance of $1,000</em></small></p>
            <p><small><em><sup>*</sup>Savings accounts are variable rate accounts. Your interest rate and annual percentage yield may change at any time without notice.</em></small></p>
            <p><small><em><sup>**</sup>Minimum account balance of $2,500</em></small></p>
            <p><small><em><sup>**</sup>The annual percentage yield assumes interest will remain on deposit, in the CD, until maturity. A withdrawal will reduce earnings.</em></small></p>
            <p><small><em><strong>Rates as of 03/04/2025</strong></em></small></p>
            </div>

            <br>
            <br>
            <br>
            <br>
                        <h2 class="is-size-3">Our Current Schedule of Fees</h2>

            <div class="table-container">
                <table class="table is-striped is-fullwidth is-hoverable">
                    <thead>
                        <tr>
                            <th>Electronic Funds Transfer (ACH)</th>
                            <th class="has-text-right">Free</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Outgoing Wire Fee</th>
                            <td class="has-text-right">$25</td>
                        </tr>
                        <tr>
                            <th>Paper Statement Fee</th>
                            <td class="has-text-right">$5</td>
                        </tr>
                    </tbody>
                </table>
            </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "Rates and Fees - WebBank";
    },

}
</script>
